import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

interface AppBarProps {
  toggleSidebar: () => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string;
}

const AppBar: React.FC<AppBarProps> = ({ toggleSidebar, setSearchQuery, searchQuery }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  // Update text direction dynamically
  useEffect(() => {
    const currentLang = i18n.language;
    document.documentElement.dir = currentLang === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  const getMediaType = () => {
    if (location.pathname.startsWith('/ebooks')) {
      return t('ebook');
    } else if (location.pathname.startsWith('/videos')) {
      return t('video');
    }
    return '';
  };

  const mediaType = getMediaType();

  const navItems = [
    { path: '/home', label: t('home') },
    { path: '/aboutUs', label: t('about') },
    { path: '/team', label: t('team') },
    { path: '/contact', label: t('contact') },
    { path: '/ebooks/home', label: t('ebooks') },
    { path: '/videos/home', label: t('videos') },
    { path: '/map', label: t('map') },
    { path: '/partners', label: t('partners') },

  ];

  const isActive = (path: string) => location.pathname === path;

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-md shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Left section: Logo and Toggle */}
          <div className="flex items-center gap-4">
            <Link to="/home" className="flex items-center gap-3">
              <img
                src="/assets/ifrane.png"
                alt="Logo"
                className="h-12 w-12 rounded-full border-2 border-green-700 p-1"
              />
              <span className="text-xl font-semibold text-green-900">OAP</span>
            </Link>
          </div>

          {/* Middle section: Navigation */}
          <div className={`hidden lg:flex items-center gap-6 ${i18n.language === 'ar' ? 'rtl:text-right' : ''}`}>
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`px-3 py-2 text-sm font-medium transition-colors duration-300 relative group ${
                  isActive(item.path) ? 'text-green-800' : 'text-gray-600 hover:text-green-800'
                }`}
              >
                {item.label}
                <span
                  className={`absolute bottom-0 left-0 w-full h-0.5 transform scale-x-0 transition-transform duration-300 bg-green-800 group-hover:scale-x-100 ${
                    isActive(item.path) ? 'scale-x-100' : ''
                  }`}
                />
              </Link>
            ))}
          </div>

          {/* Right section: Search */}
          <div className="relative flex items-center gap-4">
            {/* Language Switcher */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => handleLanguageChange('en')}
                className="text-sm text-gray-600 hover:text-green-800"
              >
                EN
              </button>
              <button
                onClick={() => handleLanguageChange('ar')}
                className="text-sm text-gray-600 hover:text-green-800"
              >
                AR
              </button>
            </div>

            {!isHomePage && (
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={`${t('search')} ${mediaType}...`}
                  className={`w-64 pl-10 pr-4 py-2 border border-green-200 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 bg-white/80 backdrop-blur-sm transition-all duration-300 ${
                    i18n.language === 'ar' ? 'rtl:pr-10 rtl:pl-4' : ''
                  }`}
                />
                {searchQuery && (
                  <button
                    onClick={() => setSearchQuery('')}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-4 h-4" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AppBar;
