import React from "react";
import InformationManagement from "../components/Channel/InformationManagement";
import CollaboratorManagement from "../components/Channel/CollaboratorsManagement";
import ContentManagement from "../components/Channel/ContentManagement";
import NotificationManagement from "../components/Channel/NotificationManagement";
import { ChannelProvider } from "../contexts/ChannelContext";

const YourChannel: React.FC = () => {
  return (
    <ChannelProvider>
    <div className="flex flex-col items-center p-8 bg-gray-100 min-h-screen">
      <div className="w-full max-w-7xl p-12 bg-white rounded-3xl shadow-md">
        <div className="flex flex-col gap-8">
            <InformationManagement />
          <div className="flex justify-between gap-8">
            <CollaboratorManagement />
            <ContentManagement />
           </div>
        </div>
        <div className="flex justify-end mt-8">
          <button className="bg-[#22c55e] text-white px-6 py-3 rounded-lg">Save</button>
        </div>
      </div>
    </div>
    </ChannelProvider>
  );
};

export default YourChannel;