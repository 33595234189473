import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { getPlaylists, createCollection as createPlaylistService, updateCollection as updateCollectionService } from '../../services/channelServices';
import { Playlist } from '../../types/domain';
import { useChannel } from '../../contexts/ChannelContext';

const ContentManagement: React.FC = () => {
  const { channelId } = useChannel();
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistDescription, setPlaylistDescription] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<number | null>(null); // Track selected playlist

  const playlistNameInputRef = useRef<HTMLInputElement>(null);
  const playlistDescriptionTextareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (channelId) {
      fetchPlaylists(channelId);
    }
  }, [channelId]);

  const fetchPlaylists = async (channelId: number) => {
    try {
      const playlistsData = await getPlaylists(channelId);
      setPlaylists(playlistsData);
    } catch (error) {
      console.error('Error fetching playlists:', error);
    }
  };

  const handleCreatePlaylist = async () => {
    if (!playlistName) return;

    if (channelId === null) {
      alert('Invalid channel ID');
      return;
    }

    setIsCreatingPlaylist(true);
    try {
      await createPlaylistService(playlistName, channelId, playlistDescription);
      alert('Collection created successfully!');
      setPlaylistName("");
      setPlaylistDescription("");
      await fetchPlaylists(channelId); // Refresh playlists only if channelId is valid
    } catch (error) {
      console.error('Error creating collection:', error);
      alert('Failed to create collection. Please try again.');
    } finally {
      setIsCreatingPlaylist(false);
    }
  };

  const handleUpdatePlaylist = async () => {
    if (!selectedPlaylistId || !playlistName || !playlistDescription || !channelId) return;

    setIsUpdating(true);
    try {
      await updateCollectionService(selectedPlaylistId, channelId ,playlistName, playlistDescription);
      alert('Collection updated successfully!');
      await fetchPlaylists(channelId!); // Refresh playlists after update
    } catch (error) {
      console.error('Error updating collection:', error);
      alert('Failed to update collection. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleEditNameClick = () => {
    setIsEditingName(true);
  };

  const handleEditDescriptionClick = () => {
    setIsEditingDescription(true);
  };

  const handleNameBlur = () => {
    setIsEditingName(false);
  };

  const handleDescriptionBlur = () => {
    setIsEditingDescription(false);
  };

  useEffect(() => {
    if (isEditingName) {
      playlistNameInputRef.current?.focus();
    }
  }, [isEditingName]);

  useEffect(() => {
    if (isEditingDescription) {
      playlistDescriptionTextareaRef.current?.focus();
    }
  }, [isEditingDescription]);

  const handlePlaylistSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;

    if (selectedValue === "") {
      setSelectedPlaylistId(null);
      setPlaylistName("");
      setPlaylistDescription("");
      return;
    }

    const selectedId = Number(selectedValue);
    setSelectedPlaylistId(selectedId);
    const selectedPlaylist = playlists.find((playlist) => Number(playlist.id) === selectedId);
    if (selectedPlaylist) {
      setPlaylistName(selectedPlaylist.name);
      setPlaylistDescription(selectedPlaylist.description);
    }
  };

  return (
    <div className="col-span-2 p-6 bg-white rounded-3xl shadow-md flex-grow">
      {/* Dropdown to select existing collections */}
      <div className="mb-6">
        <label className="block text-xl font-semibold text-gray-700 mb-2">Select Collection</label>
        <select
          value={selectedPlaylistId || ''} // Use '' if no collection is selected
          onChange={handlePlaylistSelection}
          className="mb-4 border-2 bg-white border-gray-300 rounded-lg p-4 focus:outline-none"

        >
          <option value="">New Collection</option>
          {playlists.map((playlist) => (
            <option key={playlist.id} value={playlist.id}>
              {playlist.name}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 gap-6 mb-4">
        <div className="relative mb-4">
          <label className="absolute -top-3 left-3 px-2 bg-white text-gray-500 text-xl">Collection Name</label>
          <input
            type="text"
            placeholder="Enter Collection Name"
            value={playlistName}
            onChange={(e) => setPlaylistName(e.target.value)}
            className="border-2 border-gray-300 rounded-lg p-4 w-full focus:outline-none"
            ref={playlistNameInputRef}
          />
          <div className="absolute top-3 right-3 text-gray-500 cursor-pointer" onClick={handleEditNameClick}>
            
          </div>
        </div>

        <div className="relative mb-7">
          <label className="absolute -top-3 left-3 px-2 bg-white text-gray-500 text-xl">Collection Description</label>
          <textarea
            value={playlistDescription}
            placeholder="Enter Collection Description"
            onChange={(e) => setPlaylistDescription(e.target.value)}
            className="border-2 border-gray-300 rounded-lg p-4 w-full focus:outline-none"
            ref={playlistDescriptionTextareaRef}
          />
          <div className="absolute top-3 right-3 text-gray-500 cursor-pointer" onClick={handleEditDescriptionClick}>
        
          </div>
        </div>
      </div>

      {selectedPlaylistId ? (
        <button
          className="bg-blue-500 text-white rounded-2xl px-4 py-2 w-full sm:w-auto ml-auto"
          onClick={handleUpdatePlaylist}
          disabled={isUpdating}
        >
          {isUpdating ? 'Updating...' : 'Update Collection'}
        </button>
      ) : (
        <button
          className="bg-green-500 text-white rounded-2xl px-4 py-2 w-full sm:w-auto ml-auto"
          onClick={handleCreatePlaylist}
          disabled={isCreatingPlaylist}
        >
          {isCreatingPlaylist ? 'Creating...' : 'Create New Collection'}
        </button>
      )}
    </div>
  );
};

export default ContentManagement;
