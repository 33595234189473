import React, { useRef, useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import {
  getUserChannels,
  getChannelInfo,
  updateChannelInfo,
  createChannel,
} from "../../services/channelServices";
import { Channel } from "../../types/domain";
import { useChannel } from "../../contexts/ChannelContext";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputLabel, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const InformationManagement: React.FC = () => {
  const { channelId, setChannelId, refreshChannels, channels, setChannels } =
    useChannel();
  const [channelName, setChannelName] = useState("");
  const [channelDescription, setChannelDescription] = useState("");
  const [coverPicture, setCoverPicture] = useState<File | string | null>(null);
  const [channelIcon, setChannelIcon] = useState<File | string | null>(null);

  const [isUpdating, setIsUpdating] = useState(false);

  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isCreatingChannel, setIsCreatingChannel] = useState(false);

  const coverPictureInputRef = useRef<HTMLInputElement>(null);
  const channelIconInputRef = useRef<HTMLInputElement>(null);
  const channelNameInputRef = useRef<HTMLInputElement>(null);
  const channelDescriptionTextareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    refreshChannels();
  }, []);

  useEffect(() => {
    if (channelId) {
      fetchChannelInfo(channelId);
    } else {
      setChannelName("");
      setChannelDescription("");
      setCoverPicture(null);
      setChannelIcon(null);
    }
  }, [channelId]);

  const fetchChannelInfo = async (channelId: number) => {
    try {
      const info = await getChannelInfo(channelId);
      console.log("Channel info:", info);
      setChannelName(info.name);
      setChannelDescription(info.description);
      setCoverPicture(info.cover);
      setChannelIcon(info.icon);
    } catch (error) {
      console.error("Error fetching channel info:", error);
    }
  };

  const handleCoverPictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCoverPicture(e.target.files[0]);
    }
  };

  const handleChannelIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setChannelIcon(e.target.files[0]);
    }
  };

  const handleCoverPictureClick = () => {
    coverPictureInputRef.current?.click();
  };

  const handleChannelIconClick = () => {
    channelIconInputRef.current?.click();
  };

  const handleEditNameClick = () => {
    setIsEditingName(true);
  };

  const handleEditDescriptionClick = () => {
    setIsEditingDescription(true);
  };

  useEffect(() => {
    if (isEditingName) {
      channelNameInputRef.current?.focus();
    }
  }, [isEditingName]);

  useEffect(() => {
    if (isEditingDescription) {
      channelDescriptionTextareaRef.current?.focus();
    }
  }, [isEditingDescription]);

  const handleSave = async () => {
    if (channelId) {
      setIsUpdating(true);
      try {
        const updatedChannel = await updateChannelInfo(
          channelId,
          channelName,
          channelDescription,
          channelIcon,
          coverPicture
        );
        alert("Channel information updated successfully!");

        setChannels(
          channels.map((channel) =>
            channel.id === channelId ? updatedChannel : channel
          )
        );

        setChannelName(updatedChannel.name);
        setChannelDescription(updatedChannel.description);
      } catch (error) {
        console.error("Error updating channel info:", error);
        alert("Failed to update channel information. Please try again.");
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const handleCreateChannel = async () => {
    setIsCreatingChannel(true);
    try {
      const newChannel = await createChannel(
        channelName,
        channelDescription,
        channelIcon,
        coverPicture
      );
      alert("Channel created successfully!");
      setChannels([...channels, newChannel]);
      setChannelId(newChannel.id);
      setChannelName(newChannel.name);
      setChannelDescription(newChannel.description);
      setCoverPicture(null);
      setChannelIcon(null);
    } catch (error) {
      console.error("Error creating channel:", error);
      alert("Failed to create channel. Please try again.");
    } finally {
      setIsCreatingChannel(false);
    }
  };

  return (
    <div className="col-span-2 p-6 bg-white rounded-3xl shadow-md">
      <select
        value={channelId || ""}
        onChange={(e) => setChannelId(Number(e.target.value))}
        className="mb-4 border-2 bg-white border-gray-300 rounded-lg p-4 focus:outline-none"
      >
        <option value="">New Channel</option>
        {channels.map((channel) => (
          <option key={channel.id} value={channel.id}>
            {channel.name}
          </option>
        ))}
      </select>

      <div className="relative group mb-10" onClick={handleCoverPictureClick}>
        <div className="w-full h-48 bg-gray-300 flex items-center justify-center relative rounded-t-3xl cursor-pointer overflow-hidden">
          {coverPicture ? (
            <img
              src={
                coverPicture instanceof File
                  ? URL.createObjectURL(coverPicture)
                  : coverPicture
              }
              alt="Cover"
              className="object-cover w-full h-full rounded-t-3xl"
            />
          ) : (
            <span>Cover Picture</span>
          )}
          <input
            type="file"
            accept="image/*"
            className="hidden"
            ref={coverPictureInputRef}
            onChange={handleCoverPictureChange}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-t-3xl opacity-0 group-hover:opacity-100 transition-opacity">
            <span className="text-white">Edit</span>
          </div>
        </div>
      </div>
      <div
        className="relative mt-4 mb-8 flex items-center group"
        onClick={handleChannelIconClick}
      >
        <div className="w-24 h-24 bg-gray-300 rounded-full flex items-center justify-center relative cursor-pointer overflow-hidden">
          {channelIcon ? (
            <img
              src={
                channelIcon instanceof File
                  ? URL.createObjectURL(channelIcon)
                  : channelIcon
              }
              alt="Channel Icon"
              className="object-cover w-full h-full rounded-full"
            />
          ) : (
            <span className="text-2xl">icon</span>
          )}
          <input
            type="file"
            accept="image/*"
            className="hidden"
            ref={channelIconInputRef}
            onChange={handleChannelIconChange}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
            <span className="text-white">Edit</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-4">
        <div className="relative mb-4">
          <label className="absolute -top-3 left-3 px-2 bg-white text-gray-500 text-xl">
            Channel Name
          </label>
          <input
            type="text"
            placeholder="Enter Channel Name"
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
            className="border-2 border-gray-300 rounded-lg p-4 w-full focus:outline-none"
            ref={channelNameInputRef}
          />
          <div
            className="absolute top-3 right-3 text-gray-500 cursor-pointer"
            onClick={handleEditNameClick}
          ></div>
        </div>
        <div className="relative mb-7">
          <label className="absolute -top-3 left-3 px-2 bg-white text-gray-500 text-xl">
            Channel Description
          </label>
          <textarea
            value={channelDescription}
            placeholder="Enter Channel Description"
            onChange={(e) => setChannelDescription(e.target.value)}
            className="border-2 border-gray-300 rounded-lg p-4 w-full focus:outline-none"
            ref={channelDescriptionTextareaRef}
          />
          <div
            className="absolute top-3 right-3 text-gray-500 cursor-pointer"
            onClick={handleEditDescriptionClick}
          ></div>
        </div>
      </div>
      {channelId ? (
        <button
          className="bg-green-500 text-white rounded-2xl px-4 py-2 w-full sm:w-auto ml-auto"
          onClick={handleSave}
          disabled={isUpdating}
        >
          {isUpdating ? "Updating..." : "Update Channel"}
        </button>
      ) : (
        <button
          className="bg-green-500 text-white rounded-2xl px-4 py-2 w-full sm:w-auto ml-auto"
          onClick={handleCreateChannel}
          disabled={isCreatingChannel}
        >
          {isCreatingChannel ? "Creating..." : "Create New Channel"}
        </button>
      )}
    </div>
  );
};

export default InformationManagement;
